var wipwrp = window.wipwrp || {};

wipwrp.accordion = (function($,mzr,doc,win) {

/* ----------------- private vars ------------------*/

	var	self				=	{};
	var	$win				=	$(win);
	var wrap, item, trigger, itemContent, multivisible;

/* ----------------- public methods ---------------*/


	self.init = function(opt) {

		// set defaults:
		if (opt===undefined) {
			opt = {
				wrap: '.wrap-accordion'
				,item: '.accordion-item'
				,trigger: '.accordion-trigger'
				,itemContent: '.accordion-content'
				,multivisible: false
			}
			//console.log(opt);
		}
		wrap = opt.wrap===undefined ? '.wrap-accordion' : opt.wrap;
		item = opt.item===undefined ? '.accordion-item' : opt.item;
		trigger = opt.trigger===undefined ? '.accordion-trigger' : opt.trigger;
		itemContent = opt.itemContent===undefined ? '.accordion-content' : opt.itemContent;
		multivisible = opt.multivisible===undefined ? false : opt.multivisible;

		// start accordion:
		if	($(wrap).length !== 0) {
			_action();
		}
	}


	function _action(){
		//SET INIZIAL STATE
		$(item+':not(.active)').find(itemContent).hide();

		// $(wrap+' '+trigger).bind().on('click',function(e){
		$(wrap).on('click',trigger, function(e){
			e.preventDefault();
			var objtarget =  e.target;
			var obj = {}
			obj.$btn = $(this);
			obj.$par = obj.$btn.parents(item);
			obj.$grp = obj.$btn.parents(wrap);

			if(objtarget.nodeName == 'INPUT' || objtarget.nodeName == 'input') {
				//e.preventDefault();
				//e.stopPropagation();
			} else if (!(obj.$par.hasClass('active')) && !(obj.$par.hasClass('disabled'))) {
				// se non era attivo
				if (multivisible == false) {
					obj.$grp.find(item+':not(.disabled)').find(itemContent).slideUp();
					obj.$grp.find(item+':not(.disabled)').removeClass('active');
				};

				var triggable = obj.$grp.find(trigger+'.active').add($(this));
				triggable.each(function(index, el) {
					$(this).removeClass('active');
					if($(this).data('triggerlabel')){
						_oldlabel = $(this).html();
						$(this).html($(this).data('triggerlabel'));
						$(this).data('triggerlabel',_oldlabel);
					}
				});

				obj.$par.find(itemContent).slideDown('600',function(){
					$win.trigger('resize');
				});
				obj.$btn.addClass('active');
				obj.$par.addClass('active');

			} else if (!obj.$par.hasClass('disabled')) {
					obj.$par.find(itemContent).slideUp();
					obj.$btn.removeClass('active');
					obj.$par.removeClass('active');
					if($(this).data('triggerlabel')){
						_oldlabel = $(this).html();
						$(this).html($(this).data('triggerlabel'));
						$(this).data('triggerlabel',_oldlabel);
					}
			}


		});
	}	// _action()


	return self;

})(jQuery,Modernizr, document, window);
